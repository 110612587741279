import Vue from "vue";
import Vuex from "vuex";
import VuexPersistence from "vuex-persist";
import localForage from "localforage";

import markets from "./modules/markets";
import categories from "./modules/categories";
import processes from "./modules/processes";
import problems from "./modules/problems";
import products from "./modules/products";
import videos from "./modules/videos";

Vue.use(Vuex);

const debug = process.env.NODE_ENV !== "production";

const vuexLocal = new VuexPersistence({
  strictMode: debug,
  storage: localForage,
  asyncStorage: true
});

export default new Vuex.Store({
  modules: {
    markets,
    categories,
    processes,
    problems,
    products,
    videos
  },
  mutations: {
    RESTORE_MUTATION: vuexLocal.RESTORE_MUTATION // this mutation **MUST** be named "RESTORE_MUTATION"
  },
  strict: debug,
  plugins: [vuexLocal.plugin]
});
