import axios from "@/plugins/axios";

// initial state
const state = () => ({
  items: [],
  item: {},
  selection: [] // selection of videos depending on product id
});

// getters
const getters = {
  items: (state) => state.items,
  item: (state) => state.item,
  selection: (state) => state.selection
};

// actions
const actions = {
  reset({ commit }) {
    commit("SET_ITEMS", []);
    commit("SET_ITEM", {});
    commit("SET_SELECTION", []);
  },

  fetch({ commit }) {
    return new Promise((success, failure) => {
      axios
        .post("/", {
          query: `
          {
            allvideos {
              entity {
                id
                videos
              }
            }
          }
          `
        })
        .then((response) => {
          const videos = [];
          if (response.data.data && response.data.data.allvideos) {
            response.data.data.allvideos.entity.forEach((product) => {
              const mp4 = [];
              product.videos.forEach((file) => {
                const arr_1 = file.split("####");
                const arr_2 = file.split("#####");

                mp4.push({
                  id: arr_1[0],
                  type: arr_1[1],
                  file: arr_2[1]
                });
              });

              videos.push({
                product: product.id,
                videos: mp4
              });
            });
          }

          commit("SET_ITEMS", videos);
          success(videos);
        })
        .catch((error) => {
          console.log(error);
          failure(error);
        });
    });
  },

  find({ commit, state }, id) {
    let result = null;
    if (id) {
      state.items.forEach((video) => {
        video.videos.forEach((mp4) => {
          if (mp4.id === id) {
            result = mp4;
          }
        });
      });
    }
    commit("SET_ITEM", result || {});
  },

  selectByProductID({ commit, state }, product_id) {
    let result = [];
    if (product_id) {
      state.items.forEach((video) => {
        if (video.product === product_id) result = video.videos;
      });
    }
    commit("SET_SELECTION", result);
  }
};

// mutations
const mutations = {
  SET_ITEMS(state, value) {
    state.items = value;
  },
  SET_ITEM(state, value) {
    state.item = value;
  },
  SET_SELECTION(state, value) {
    state.selection = value;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
