import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

import IconSmartphone from '@/icons/IconSmartphone.vue'
import IconMore from '@/icons/IconMore.vue'
import IconCross from '@/icons/IconCross.vue'
import iconCircleArrow from '@/icons/IconCircleArrow.vue'
import IconAngle from '@/icons/IconAngle.vue'

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    options: {
      customProperties: true
    },
    light: true,
    themes: {
      light: {
        primary: '#003761',
        accent: '#009fe3',
        secondary: '#e77a24',
        info: '#EBF1F1',
        warning: '#FFCA3B',
        error: '#F2866A',
        success: '#B4DE43'
      }
    }
  },
  icons: {
    iconfont: 'mdi',
    values: {
      smartphone: {
        component: IconSmartphone
      },
      more: {
        component: IconMore
      },
      cross: {
        component: IconCross
      },
      angle: {
        component: IconAngle
      },
      circleArrow: {
        component: iconCircleArrow
      }
    }
  }
});
