import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import axios from "./plugins/axios";
import "./plugins/vue-carousel";
import "./plugins/vue-offline";
import "./registerServiceWorker";

Vue.config.productionTip = false;

const lang = window.CONFIG.language;
const api = window.CONFIG.api;

// Getting language file
// then start the application

Vue.prototype.$load_total = [];
Vue.prototype.$load_length = [];
Vue.prototype.$loaded = [];

axios
	.get(lang + ".json", {
		baseURL: "/"
	})
	.then((strings) => {
		Vue.use({
			install(Vue) {
				Vue.prototype.$http = axios;
				Vue.prototype.$lang = lang;
				Vue.prototype.$api = api;
				Vue.prototype.$strings = strings.data;
			}
		});

		new Vue({
			router,
			store,
			vuetify,
			render: (h) => h(App)
		}).$mount("#app");
	});
