import { render, staticRenderFns } from "./HeaderFake.vue?vue&type=template&id=40af3a90&scoped=true&"
var script = {}
import style0 from "./HeaderFake.vue?vue&type=style&index=0&id=40af3a90&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "40af3a90",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VImg } from 'vuetify/lib/components/VImg';
import { VSpacer } from 'vuetify/lib/components/VGrid';
installComponents(component, {VImg,VSpacer})
