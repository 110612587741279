<template>
  <v-app id="app">
    <!-- The top app bar -->

    <v-app-bar class="cbo-header" color="white" height="84px" flat app>
      <v-spacer class="hidden-md-and-up" />
      <router-link :to="{ name: 'home' }" class="header-logo">
        <img src="./assets/images/logo-blue.svg" alt="" />
      </router-link>
      <nav class="header-nav hidden-sm-and-down">
        <a :href="$strings.nav_link_1_href" target="_blank">
          {{ $strings.nav_link_1_label }}
        </a>
        <a :href="$strings.nav_link_2_href" target="_blank">
          {{ $strings.nav_link_2_label }}
        </a>
        <a :href="$strings.nav_link_3_href" target="_blank">
          {{ $strings.nav_link_3_label }}
        </a>
      </nav>
      <v-spacer />
      <nav v-if="$strings.nav_button_href !== '' && $strings.nav_button_label !== ''" class="header-nav hidden-sm-and-down">
        <Button :href="$strings.nav_button_href" target="_blank" big>
          {{ $strings.nav_button_label }}
        </Button>
      </nav>
    </v-app-bar>

    <!-- The main application content -->

    <v-main class="cbo-main">
      <div class="main-inner">
        <!-- 
          We set a unique key on router-view
          to force re-render when using same component 
        -->

        <router-view :key="$route.path" />
      </div>
    </v-main>

    <!-- The desktop categories menu switch -->

    <button
      type="button"
      :class="navMoreSwitchClasses"
      @click="drawer = !drawer"
    >
      <span class="switch-label">
        Menu
        <v-icon>$vuetify.icons.values.angle</v-icon>
      </span>
    </button>

    <!-- The product categories menu -->

    <v-navigation-drawer
      v-model="drawer"
      class="cbo-navmore"
      width="100%"
      height="100vh"
      fixed
      bottom
      hide-overlay
      touchless
      stateless
    >
      <HeaderFake />
      <Container transparent>
        <div class="navmore-title cbo-title-1 small">
          {{ $strings.menu_drawer_title }}
        </div>
        <v-list class="navmore-list">
          <v-list-item
            v-for="(category, i_category) in categories"
            :key="'category_' + i_category"
            :to="{ name: 'category', params: { category: category.id } }"
            class="list-el"
            @click="drawer = false"
          >
            {{ category.name }}
          </v-list-item>
        </v-list>
      </Container>
    </v-navigation-drawer>

    <!-- The bottom app bar -->

    <v-app-bar
      class="cbo-toolbar hidden-md-and-up"
      color="transparent"
      height="84px"
      flat
      bottom
      fixed
    >
      <v-btn
        class="toolbar-button"
        :href="$strings.menu_phone_href"
        color="#ccc"
        depressed
        text
      >
        <v-icon>$vuetify.icons.values.smartphone</v-icon>
        {{ $strings.menu_phone_label }}
      </v-btn>

      <v-spacer />
      <v-btn
        :class="['toolbar-more', { 'more--active': drawer }]"
        color="accent"
        fab
        depressed
        dark
        @click="drawer = !drawer"
      >
        <v-icon>$vuetify.icons.values.more</v-icon>
      </v-btn>
      <v-spacer />

      <v-btn
        class="toolbar-button"
        :href="$strings.menu_email_href"
        color="#ccc"
        depressed
        text
      >
        <v-icon>mdi-email</v-icon>
        {{ $strings.menu_email_label }}
      </v-btn>
    </v-app-bar>

    <!-- The loading modal -->

    <v-dialog v-model="loadingModal" persistent width="300">
      <v-card color="white">
        <v-card-text class="pt-3">
          {{ $strings.modal_loading_text }}
          <span v-if="!isNaN(loadingCount) && loadingTotal">
            {{ loadingCount }}/{{ loadingTotal }}
          </span>
          <v-progress-linear
            indeterminate
            color="primary"
            class="mt-2 mb-2"
            light
          ></v-progress-linear>
          <div v-if="!isNaN(loadingCount) && loadingTotal">
            {{ loadingTitle }}
            <v-progress-linear
              :indeterminate="!$loaded"
              color="primary"
              class="mt-2"
              :value="$loaded"
              :key="loadingTitle"
            ></v-progress-linear>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- The refresh modal prompt -->

    <v-dialog v-model="refreshModal" width="300">
      <v-card>
        <v-card-title class="headline">
          {{ $strings.modal_refresh_title }}
        </v-card-title>

        <v-card-text>
          {{ $strings.modal_refresh_text }}
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="refreshModal = false">
            {{ $strings.modal_refresh_button_no }}
          </v-btn>
          <v-btn color="primary" text @click="refreshData()">
            {{ $strings.modal_refresh_button_yes }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- The need connection modal -->

    <v-dialog v-model="offlineModal" persistent width="300">
      <v-card>
        <v-card-title class="headline">
          {{ $strings.modal_offline_title }}
        </v-card-title>

        <v-card-text>
          {{ $strings.modal_offline_text }}
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- The refresh app modal -->

    <v-dialog v-model="updateExists" persistent width="300">
      <v-card>
        <v-card-title class="headline">
          {{ $strings.modal_refreshapp_title }}
        </v-card-title>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="refreshApp">
            {{ $strings.modal_refreshapp_button }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-app>
</template>

<script>
import "@/assets/scss/main.scss";
import store from "@/store";
import localForage from "localforage";
import { mapGetters } from "vuex";
import update from './mixins/update'

import Container from "@/components/Container";
import Button from "@/components/Button";
import HeaderFake from "@/components/HeaderFake";

export default {
  components: {
    Container,
    Button,
    HeaderFake
  },
  computed: {
    ...mapGetters({
      categories: "categories/items"
    }),
    navMoreSwitchClasses() {
      return ["cbo-navmore-switch", { "switch--active": this.drawer }];
    }
  },
  data: () => ({
    drawer: false,
    loadingCount: null,
    loadingTotal: null,
    loadingModal: true,
    loadingTitle: '',
    refreshModal: false,
    offlineModal: false
  }),
  mixins: [update],
  async mounted() {

    // Wait for store to be ready
    // before trying to fetch data
    // because of async localForage

    await store.restored;
    this.loadingModal = false;

    // Now the store is ready
    // Let's see if we got someting in markets

    if (
      this.$store.state.markets &&
      this.$store.state.markets.items.length > 0
    ) {
      // If we already have data
      // we propose to refresh them

      if(this.isOnline)
        this.refreshModal = true;

      //this.$store.dispatch("videos/reset");
      //this.$store.dispatch("videos/fetch");

    } else {
      // If we are online
      // let's fetch the whole API data

      if (this.isOnline) this.loadData();
      // If we are offline
      // let's indicate to the user
      // that we need a connection
      else this.offlineModal = true;
    }
  },
  watch: {
    refreshExists(){
      if(this.refreshExists === true){
        this.refreshModal = false;
        this.offlineModal = false;
      }
    }
  },
  methods: {
    refreshData() {
      this.refreshModal = false;
      this.loadData();
    },
    refreshApp() {
      this.updateExists = false
      // Make sure we only send a 'skip waiting' message if the SW is waiting
      if (!this.registration || !this.registration.waiting) return
      // Send message to SW to skip the waiting and activate the new SW
      this.registration.waiting.postMessage({ type: 'SKIP_WAITING' })
    },
    loadData() {
      this.loadingModal = true;
      this.loadingCount = 0;
      this.loadingTotal = 4;

      this.$store.dispatch("markets/reset");
      this.$store.dispatch("processes/reset");
      this.$store.dispatch("problems/reset");
      this.$store.dispatch("products/reset");
      this.$store.dispatch("categories/reset");
      //this.$store.dispatch("videos/reset");

      localForage.clear();

      this.loadingTitle = this.$strings.load_title_markets;
      this.$store.dispatch("markets/fetch").then(() => {
        this.loadingCount++;

        this.loadingTitle = this.$strings.load_title_processes;
        this.$store.dispatch("processes/fetch").then(() => {
          this.loadingCount++;

          this.loadingTitle = this.$strings.load_title_problems;
          this.$store.dispatch("problems/fetch").then(() => {
            this.loadingCount++;

            this.loadingTitle = this.$strings.load_title_products;
            this.$store.dispatch("products/fetch").then(() => {
              this.loadingCount++;

              this.loadingTitle = this.$strings.load_title_categories;
              this.$store.dispatch("categories/fetch").then(() => {
                this.loadingCount++;
                this.loadingModal = false;

                /*this.loadingTitle = this.$strings.load_title_videos;
                this.$store.dispatch("videos/fetch").then(() => {
                  this.loadingCount++;
                  this.loadingModal = false;
                });*/
              });
            });
          });
        });
      });
    }
  }
};
</script>

<style lang="scss">
@import "~vuetify/src/styles/settings/_variables";

.cbo-header {
  .header-logo {
    display: block;
    width: 8rem;

    img{
      display: block;
      width: 100%;
      height: auto;
    }
  }

  .header-nav {
    margin-left:5rem;

    a:not(.cbo-button) {
      display: inline-block;
      color: var(--v-primary-base);
      font-size: 0.88rem;
      font-weight: 700;
      font-style: normal;
      letter-spacing: normal;
      line-height: normal;
      margin: 0 2rem 0 0;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}

.cbo-main {
  .main-inner {
    display: flex;
    width: 100%;
    min-height: 100%;
    overflow: hidden;
  }
}

.cbo-toolbar.v-sheet.v-app-bar.v-toolbar {
  z-index: 7;

  &:before {
    content: "";
    display: block;
    width: 5rem;
    height: 5rem;
    border-radius: 50%;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateY(-27%) translateX(-50%);
    box-shadow: 0 0 1rem rgba(0, 0, 0, 0.16);
  }

  .v-toolbar__content {
    background: white !important;
    border-radius: 2rem 2rem 0 0;
    box-shadow: 0 0 1rem rgba(0, 0, 0, 0.16);
  }

  .toolbar-button {
    margin-left: 1rem;
    margin-right: 1rem;
    width: 3rem;
    padding: 0;

    .v-btn__content {
      .v-icon {
        margin-bottom: 0.25rem;
      }

      flex-direction: column;
      text-transform: none;
      font-size: 0.75rem;
      font-weight: 400;
      font-style: normal;
      letter-spacing: normal;
    }
  }

  .toolbar-more {
    width: 5rem;
    height: 5rem;
    border: 10px solid white !important;
    transform: translateY(-30%);

    .v-icon {
      font-size: 2rem;
      width: 2rem;
      height: 2rem;
      transition: transform 0.3s ease-in-out;

      .v-icon__component {
        width: 2rem;
        height: 2rem;
      }
    }

    &.more--active .v-icon {
      transform: rotate(45deg);
    }
  }
}

.cbo-navmore-switch {
  display: none;
  position: fixed;
  top: 0;
  left: -0.25rem;
  height: 100vh;
  width: 2.75rem;
  background: url(./assets/images/cat-nav-switch.svg) no-repeat right 40% center;
  background-size: 6.25rem auto;
  z-index: 100;

  &:before {
    content: "";
    display: block;
    width: 1.5rem;
    height: 100%;
    background: var(--v-primary-base);
    position: absolute;
    top: 0;
    left: 0;
  }

  .switch-label {
    display: block;
    color: white;
    text-transform: uppercase;
    transform: rotate(-90deg);
    white-space: nowrap;
    font-size: 0.75rem;
    font-weight: 300;
    font-style: normal;
    letter-spacing: 0.15em;
    margin-bottom: -0.75rem;

    .v-icon {
      transform: rotate(90deg);
      font-size: 0.81rem;
      width: 0.81rem;
      height: 0.81rem;
      color: white;
    }
  }

  &.switch--active {
    .switch-label {
      .v-icon {
        transform: rotate(-90deg);
      }
    }
  }
}

.cbo-navmore {
  max-height: 100vh !important;
  background: rgba(0, 55, 97, 0.93) !important;

  .navmore-title {
    text-align: center;
    margin: 2rem 0;
  }

  .navmore-list {
    padding-bottom: 8rem;

    .list-el.v-list-item.theme--light {
      text-align: center;
      align-items: center;
      justify-content: center;
      color: white !important;
      min-height: auto;
      flex-direction: column;

      &:after {
        content: "";
        display: block;
        width: 2rem;
        height: 1px;
        background: white;
        margin: 1rem auto;
      }

      &:last-child {
        &:after {
          display: none;
        }
      }
    }
  }
}

@media #{map-get($display-breakpoints, 'md-and-up')} {
  .cbo-header {
    height: 7.75rem;
    min-height: 7.75rem;
    padding-left: 1.5rem;
    padding-right:1.5rem;

    .header-logo {
      width: 13.75rem;
    }

    .v-toolbar__content {
      height: 7.75rem !important;
    }
  }

  .cbo-navmore-switch {
    display: block;
  }

  .cbo-main {
    padding-left: 1rem !important;
    padding-top: 7.75rem !important;
  }
}
</style>
