<template>
  <v-btn
    :to="to"
    :href="href"
    :target="target"
    :color="color"
    :outlined="outlined"
    :elevation="elevation"
    :class="cssClasses"
    rounded
  >
    <slot />
  </v-btn>
</template>

<script>
export default {
  name: "Button",
  props: {
    to: [String, Object],
    href: String,
    target: String,
    color: {
      type: String,
      default: "secondary"
    },
    outlined: Boolean,
    elevation: {
      type: Number,
      default: 0
    },
    big: Boolean
  },
  computed: {
    cssClasses() {
      return ["cbo-button", { "button--big": this.big }];
    }
  }
};
</script>

<style lang="scss" scoped>
.cbo-button {
  height: 2.25rem !important;

  &::v-deep {
    .v-btn__content {
      text-transform: none;
      letter-spacing: 0;
      font-size: 0.69rem;
    }
  }

  &.button--big {
    height: 2.88rem !important;
    padding: 0 1.5rem !important;

    &::v-deep {
      .v-btn__content {
        font-size: 0.88rem;
      }
    }
  }
}
</style>
