<template>
  <div :class="['cbo-videoplayer', {'videoplayer--cover':cover}]">
    <video controls playsinline v-if="isOnline && src">
      <source :src="src" />
    </video>
    <div v-else class="videoplayer-offline">
      <div v-html="$strings.video_offline_text"></div>
    </div>
  </div>
</template>
<script>
export default {
  name: "Video",
  props: {
    src: String,
    cover: Boolean
  }
};
</script>
<style lang="scss">
.cbo-videoplayer{
  position:relative;
  background:black;

  video{
    display: block;
    width: 100%;
    height: 100%;
  }

  .videoplayer-offline{
    display: flex;
    align-items:center;
    justify-content: center;
    text-align: center;
    position: absolute;
    top:0;
    left:0;
    width: 100%;
    height: 100%;
    padding:1.5rem;
    color:white;
    font-size:1rem;
  }

  &.videoplayer--cover{
    video{
      position:absolute;
      top:0; left:0;
      object-fit:cover;
      object-position: center;
    }
  }
}
</style>
