import Vue from "vue";
import axios from "@/plugins/axios";

// initial state
const state = () => ({
  items: [],
  item: {},
  selection: [] // selection of problems depending on current process
});

// getters
const getters = {
  items: (state) => state.items,
  item: (state) => state.item,
  selection: (state) => state.selection
};

// actions
const actions = {
  reset({ commit }) {
    commit("SET_ITEMS", []);
    commit("SET_ITEM", {});
    commit("SET_SELECTION", []);
  },

  fetch({ commit }) {
    return new Promise((success, failure) => {
      axios
        .post("/", {
          query: `
          {
            allprobleme {
              entity {
                id
                title
                language
                question
                entity_type
                subProblemes {
                  id
                  title
                }
                subProduits {
                  id
                  title
                  thumbnail {
                    file
                  }
                }
              }
            }
          }
          `
        })
        .then((response) => {
          const problems = [];
          if (response.data.data && response.data.data.allprobleme) {
            response.data.data.allprobleme.entity.forEach((problem) => {
              if (problem && problem.language === Vue.prototype.$lang)
                problems.push(problem);
            });
          }
          commit("SET_ITEMS", problems);
          success(problems);
        })
        .catch((error) => {
          console.log(error);
          failure(error);
        });
    });
  },

  find({ commit, state }, id) {
    const item = state.items.find(function(problem) {
      if (problem.id === id) return problem;
    });
    commit("SET_ITEM", item || {});
  },

  selectByProcessus({ commit, state, rootState }) {
    const items = [];
    if (rootState.processes.item && rootState.processes.item.subProblemes) {
      rootState.processes.item.subProblemes.forEach((problem) => {
        state.items.forEach((subproblem) => {
          if (problem.id === subproblem.id) items.push(subproblem);
        });
      });
    }
    commit("SET_SELECTION", items);
  },

  selectByProblem({ commit, state }, id) {
    const items = [];

    const ids = id.split("-");
    id = ids[ids.length - 1];

    const problem = state.items.find((prob) => {
      return prob.id === id;
    });

    if (problem && problem.subProblemes) {
      problem.subProblemes.forEach((prob) => {
        state.items.forEach((subprob) => {
          if (subprob.id === prob.id) items.push(subprob);
        });
      });
    }
    commit("SET_SELECTION", items);
  }
};

// mutations
const mutations = {
  SET_ITEMS(state, value) {
    state.items = value;
  },
  SET_ITEM(state, value) {
    state.item = value;
  },
  SET_SELECTION(state, value) {
    state.selection = value;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
