<template>
  <Container class="d-flex align-md-center">
    <div class="cbo-home">
      <v-row>
        <v-col cols="6" md="3">
          <router-link :to="{ name: 'markets' }" class="cbo-choice">
            <span class="choice-icon">
              <img src="../assets/images/entry-choice.svg" alt="" />
            </span>
            <span class="choice-label">
              {{ $strings.home_entry_help }}
            </span>
          </router-link>
        </v-col>
        <v-col cols="6" md="3">
          <div class="cbo-choice choice--disabled">
            <span class="choice-icon">
              <img src="../assets/images/entry-assistance.svg" alt="" />
            </span>
            <span class="choice-label">
              {{ $strings.home_entry_assistance }}
            </span>
          </div>
        </v-col>
        <v-col cols="6" md="3">
          <div class="cbo-choice choice--disabled">
            <span class="choice-icon">
              <img src="../assets/images/entry-appointment.svg" alt="" />
            </span>
            <span class="choice-label">
              {{ $strings.home_entry_appointment }}
            </span>
          </div>
        </v-col>
        <v-col cols="6" md="3">
          <div class="cbo-choice choice--disabled">
            <span class="choice-icon">
              <img src="../assets/images/entry-configurator.svg" alt="" />
            </span>
            <span class="choice-label">
              {{ $strings.home_entry_configurator }}
            </span>
          </div>
        </v-col>
      </v-row>
    </div>
  </Container>
</template>

<script>
//import { mapGetters } from 'vuex';
import Container from "@/components/Container";

export default {
  name: "Home",
  components: {
    Container
  }
};
</script>

<style lang="scss" scoped>
.cbo-home {
  padding-bottom: 5rem;

  &:before {
    content: "";
    display: block;
    width: 3.88rem;
    height: 3.88rem;
    background: url(../assets/images/plus-orange.svg) no-repeat center;
    background-size: contain;
    margin: 2rem 0;
  }

  &:after {
    content: "";
    display: block;
    width: 1.88rem;
    height: 1.88rem;
    background: url(../assets/images/plus-blue.svg) no-repeat center;
    background-size: contain;
    margin: 1rem 0 2rem auto;
  }
}

.cbo-choice {
  display: block;
  text-decoration: none;

  .choice-icon {
    display: block;
    position: relative;
    width: 100%;
    padding-bottom: 100%;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.16);
    background-color: var(--v-accent-base);
    border: 4px solid var(--v-accent-base);
    margin: 0 0 0.5rem 0;
    border-radius: 1rem;

    img {
      display: block;
      width: 60%;
      height: auto;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateX(-50%) translateY(-50%);
    }
  }

  .choice-label {
    display: block;
    color: white;
    font-size: 1rem;
    font-weight: 700;
    font-style: normal;
    letter-spacing: normal;
    line-height: 1.5em;
    text-align: center;
  }

  &.choice--disabled {
    .choice-icon {
      background: none;
      border-color: #587c97;
    }

    .choice-label {
      color: #587c97;
    }
  }
}
</style>
