import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store";

import Home from "../views/Home.vue";
import Markets from "../views/Markets.vue";
import Processes from "../views/Processes.vue";
import Problems from "../views/Problems.vue";
import Products from "../views/Products.vue";
import Product from "../views/Product.vue";
import Category from "../views/Category.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: Home
  },
  {
    path: "/marches",
    name: "markets",
    component: Markets
  },
  {
    path: "/marches/:market",
    name: "processes",
    component: Processes
  },
  {
    path: "/marches/:market/processus/:processus",
    name: "problems",
    component: Problems
  },
  {
    path: "/marches/:market/processus/:processus/problems/:problem",
    name: "subproblems",
    component: Problems
  },
  {
    path: "/marches/:market/processus/:processus/problems/:problem/produits",
    name: "products",
    component: Products
  },
  {
    path: "/produit/:product",
    name: "product",
    component: Product
  },
  {
    path: "/gamme/:category",
    name: "category",
    component: Category
  } /*,
  {
    path: "/about",
    name: "About",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ /* "../views/About.vue")
  }*/
];

console.log(process.env.BASE_URL);

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

// We need to wait for async localForage
// to be ready before we show nextRoute
// https://www.npmjs.com/package/vuex-persist#note-on-localforage-and-async-stores

const waitForStorageToBeReady = async (to, from, next) => {
  await store.restored;
  next();
};
router.beforeEach(waitForStorageToBeReady);

// Exporting router
// Done !

export default router;
