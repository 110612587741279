<template>
  <div class="cbo-wrapper wrapper--md-info">
    <Container transparent class="cbo-introduction-wrapper">
      <div class="cbo-introduction">
        <h1>{{ $strings.markets_title }}</h1>
        <p>
          {{ $strings.markets_intro }}
        </p>
      </div>
    </Container>

    <Container class="cbo-markets" full-height>
      <h2 class="markets-title">
        {{ $strings.markets_subtitle }}
      </h2>

      <div class="markets-list">
        <v-row dense class="list-grid hidden-md-and-up">
          <v-col
            v-for="(market, i_market) in markets"
            :key="'market_' + i_market"
            cols="6"
            sm="4"
          >
            <router-link
              :to="{ name: 'processes', params: { market: market.id } }"
              class="list-el"
            >
              <span class="el-icon">
                <img :src="market.logo" alt="" />
              </span>
              <span class="el-label">
                {{ market.name }}
              </span>
            </router-link>
          </v-col>
        </v-row>

        <carousel
          :per-page="6"
          :pagination-enabled="false"
          :navigation-enabled="true"
          :navigation-next-label="''"
          :navigation-prev-label="''"
          centerMode
          class="list-carousel hidden-sm-and-down"
        >
          <slide
            v-for="(market, i_market) in markets"
            :key="'market_slide_' + i_market"
            cols="6"
          >
            <router-link
              :to="{ name: 'processes', params: { market: market.id } }"
              class="list-el"
            >
              <span class="el-icon">
                <img :src="market.logo" alt="" />
              </span>
              <span class="el-label">
                {{ market.name }}
              </span>
            </router-link>
          </slide>
        </carousel>
      </div>
    </Container>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Container from "@/components/Container";

export default {
  name: "Markets",
  components: {
    Container
  },
  computed: {
    ...mapGetters({
      markets: "markets/items"
    })
  }
};
</script>

<style lang="scss" scoped>
.cbo-markets {
  .markets-list {
    padding-bottom: 7rem;

    .list-el {
      display: block;
      text-decoration: none;
      color: white;
      text-align: center;
      box-shadow: 0 0 1rem #002541;
      border-radius: 0.5rem;
      background-color: var(--v-primary-base);
      padding: 1rem;
      min-height: 100%;
      transition: background 0.3s;

      .el-icon {
        display: block;
        width: 3.5rem;
        height: 3.5rem;
        position: relative;
        margin: 0 auto 0.5rem auto;

        img {
          display: block;
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      .el-label {
        display: block;
        color: white;
        font-size: 0.81rem;
        font-weight: 700;
        font-style: normal;
        letter-spacing: normal;
        line-height: 1.23em;
      }

      &:hover {
        background: var(--v-accent-base);
      }
    }

    .list-carousel {
      .list-el {
        margin: 0 1rem;
        min-height: 100%;
      }

      &::v-deep {
        .VueCarousel-slide {
          padding: 1rem 0;
        }

        .VueCarousel-navigation-prev,
        .VueCarousel-navigation-next {
          display: block;
          width: 1.75rem;
          height: 1.75rem;
          background-repeat: no-repeat;
          background-size: 100% auto;
          background-position: center;
        }

        .VueCarousel-navigation-prev {
          background-image: url(../assets/images/icon-circle-arrow-left.svg);
        }

        .VueCarousel-navigation-next {
          background-image: url(../assets/images/icon-circle-arrow-right.svg);
        }
      }
    }
  }
}
</style>
