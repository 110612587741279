<template>
  <div :class="wrapperClasses">
    <v-container class="cbo-container">
      <slot />
    </v-container>
  </div>
</template>

<script>
export default {
  name: "Container",
  props: {
    transparent: Boolean,
    white: Boolean,
    fullHeight: Boolean,
    noRadius: Boolean,
    small: Boolean
  },
  computed: {
    wrapperClasses() {
      return [
        "cbo-container-wrapper",
        { "wrapper--transparent": this.transparent },
        { "wrapper--white": this.white },
        { "wrapper--full-height": this.fullHeight },
        { "wrapper--no-radius": this.noRadius },
        { "wrapper--small": this.small }
      ];
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~vuetify/src/styles/settings/_variables";

.cbo-container-wrapper {
  background: var(--v-primary-base);
  border-radius: 2rem 2rem 0 0;
  flex-grow: 1;

  &.wrapper--transparent {
    background: none;
  }

  &.wrapper--white {
    background: white;
  }

  &.wrapper--full-height {
    min-height: 100%;
  }

  &.wrapper--no-radius {
    border-radius: 0;
  }
}

.cbo-container.container {
  padding-left: 2rem;
  padding-right: 2rem;
}

@media #{map-get($display-breakpoints, 'sm-and-up')} {
  .cbo-container.container {
    padding-left: 4rem;
    padding-right: 4rem;
    max-width: 76.75rem;
  }

  .cbo-container-wrapper.wrapper--small .cbo-container.container{
    max-width:60.00rem;
  }
}

@media #{map-get($display-breakpoints, 'md-and-up')} {
  .cbo-container-wrapper {
  }
}
</style>
