<template>
  <div class="cbo-wrapper">
    <Container>
      <div class="cbo-summary">
        <h1 class="summary-title cbo-title-2">
          {{ $strings.problems_title }}
        </h1>
        <router-link :to="{ name: 'markets' }"  class="summary-market">
          <span v-if="market.logo" class="market-icon">
            <img :src="market.logo" alt="" />
          </span>
          <span class="market-label">
            {{ market.name }}
          </span>
        </router-link>
        <div v-if="market && processus.id" class="summary-breadcrumbs">
          <router-link
            :to="{
              name: 'processes',
              params: { market: market.id }
            }"
          >
            {{ processus.title }}
          </router-link>
          <span
            v-for="(problem_id, index) in routeProblemsIds"
            :key="'bc_problem_' + index"
          >
            <span v-if="index % 2 === 0" class="sep">&gt;</span>
            <router-link
              :to="{
                name: 'problems',
                params: { market: market.id, processus: processus.id }
              }"
              class="bc"
            >
              {{ getSubProblemName(problem_id) }}
            </router-link>
          </span>
        </div>
      </div>
    </Container>

    <div class="cbo-summary-angle">
      <v-icon color="white"> $vuetify.icons.values.angle </v-icon>
    </div>

    <Container class="cbo-problems" white>
      <h2 class="problems-title">
        {{ $strings.problems_subtitle }}
      </h2>

      <v-row class="problems-list">
        <v-col
          v-for="(problem, index) in problemsSelection"
          :key="'problem_' + index"
          cols="12"
          md="6"
          class="pb-1"
        >
          <!-- Subproducts case -->
          <router-link
            v-if="problem.subProduits && problem.subProduits.length > 0"
            :to="{
              name: 'products',
              params: {
                market: market.id,
                processus: processus.id,
                problem: getProblemIds(problem.id)
              }
            }"
            class="list-el"
          >
            <span class="el-button">{{ problem.title }}</span>
          </router-link>

          <!-- Subproblems case -->
          <div
            v-else
            :class="['list-el', { 'el--active': open === problem.id }]"
          >
            <button class="el-button" @click="switchProblem(problem.id)">
              {{ problem.title }}
              <div class="el-icon">
                <v-icon color="white"> $vuetify.icons.values.angle </v-icon>
              </div>
            </button>
            <div class="el-content">
              <div class="content-label" v-html="problem.question"></div>
              <div class="content-buttons">
                <Button
                  v-for="(subproblem, s_index) in problem.subProblemes"
                  :key="'subproblem_' + s_index"
                  :to="{
                    name: getRouteName(subproblem),
                    params: {
                      market: market.id,
                      processus: processus.id,
                      problem: getProblemIds([problem.id, subproblem.id])
                    }
                  }"
                >
                  {{ getSubProblemName(subproblem.id) }}
                </Button>
              </div>
            </div>
          </div>
        </v-col>
      </v-row>
    </Container>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Container from "@/components/Container";
import Button from "@/components/Button";

export default {
  name: "Problems",
  components: {
    Container,
    Button
  },
  computed: {
    ...mapGetters({
      markets: "markets/items",
      market: "markets/item",
      processes: "processes/items",
      processus: "processes/item",
      problems: "problems/items",
      problemsSelection: "problems/selection"
    })
  },
  watch: {
    "$route.params.problem": {
      handler: function(problems) {
        if (problems) this.routeProblemsIds = problems.split("-");
      },
      deep: true,
      immediate: true
    },
    markets: {
      handler: function() {
        this.$store.dispatch("markets/find", this.$route.params.market);
      },
      immediate: true
    },
    processes: {
      handler: function() {
        this.$store.dispatch("processes/find", this.$route.params.processus);
      },
      immediate: true
    },
    processus: {
      handler: function() {
        if (!this.$route.params.problem)
          this.$store.dispatch(
            "problems/selectByProcessus",
            this.$route.params.processus
          );
      },
      immediate: true
    },
    problems: {
      handler: function() {
        if (this.$route.params.problem)
          this.$store.dispatch(
            "problems/selectByProblem",
            this.$route.params.problem
          );
        else
          this.$store.dispatch(
            "problems/selectByProcessus",
            this.$route.params.processus
          );
      },
      immediate: true
    }
  },
  data: () => ({
    open: null,
    routeProblemsIds: []
  }),
  methods: {
    switchProblem(id) {
      this.open = this.open === id ? null : id;
    },
    getSubProblem(id) {
      const problem = this.problems.find((prob) => {
        return prob.id === id;
      });

      return problem;
    },
    getProblemIds(id) {
      const ids = Array.isArray(id) ? id.join("-") : id;

      if (this.$route.params.problem)
        return this.$route.params.problem + "-" + ids;
      else return ids;
    },
    getSubProblemName(id) {
      const problem = this.problems.find((prob) => {
        return prob.id === id;
      });

      return problem && problem.title ? problem.title : "Not found";
    },
    getRouteName(problem) {
      problem = this.getSubProblem(problem.id);
      if (problem.subProduits && problem.subProduits.length > 0) {
        return "products";
      } else {
        return "subproblems";
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.cbo-problems {
  background: var(--v-primary-base);

  .problems-list {
    padding-bottom: 7rem;
    max-width: 37.5rem;
    margin: 0 auto;

    .list-el {
      display: block;
      position: relative;
      text-decoration: none;
      width: 100%;
      box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
      border-radius: 0.75rem;
      background-color: white;
      color: var(--v-primary-base);
      font-size: 0.81rem;
      font-weight: 700;
      font-style: normal;
      letter-spacing: normal;
      line-height: 1.38em;
      text-align: left;
      padding-bottom: 0.1px;

      .el-button {
        border: none;
        background: none;
        display: block;
        width: 100%;
        text-align: left;
        padding: 0.75rem 1rem;
      }

      .el-icon {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 1.31rem;
        height: 1.31rem;
        position: absolute;
        top: 0.6rem;
        right: 0.6rem;
        background: var(--v-accent-base);
        border-radius: 50%;
        transition: transform 0.2s ease-in-out;

        .v-icon {
          font-size: 0.75rem;
          width: 0.75rem;
          height: 0.75rem;
          transform: rotate(90deg);
          margin-top: 0.1rem;
        }
      }

      .el-content {
        display: none;
        border-left: 3px solid var(--v-secondary-base);
        padding: 0 1rem 0 0.5rem;
        margin-top: 0.5rem;
        margin-left: 0.75rem;
        margin-bottom: 0.75rem;

        .content-label {
          font-weight: normal;
          font-size: 0.75rem;

          &::v-deep p{
            margin:0 !important;
          }
        }

        .content-buttons {
          .v-btn {
            margin-right: 1rem;
            margin-top: 0.5rem;

            &::v-deep .v-btn__content {
              text-transform: none;
              font-weight: normal;
              letter-spacing: normal;
            }
          }
        }
      }

      &.el--active {
        .el-icon {
          transform: scaleY(-1);
        }

        .el-content {
          display: block;
        }
      }
    }
  }
}
</style>
